import {log} from "./logger";

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import Inputmask from 'inputmask';

import Menu from './initializers/init_menu';
import Preferences from './initializers/init_preferences';
import PhoneInputMask from './initializers/init_phone_input_mask';
import FullCalendarJS from "./initializers/init_fullcalendar";
import Modal from './initializers/init_modal'
import CustomElementPasswordToggle from 'custom-element-password-toggle';

const Initializers = {
  run() {
    log("Initializing...");

    Rails.start();
    Turbolinks.start();
    ActiveStorage.start();

    $(document).on("turbolinks:load", () => {
      Menu.init();
      Preferences.init();
      FullCalendarJS.init();
      PhoneInputMask.init();

      // Last position to wait for all elements above
      Modal.init();
    });

    $(document).on("turbolinks:visit", () => {
    });
  }
};

export default Initializers;
