import 'channels'
import 'stylesheets/application.scss'

require("trix")
require("@rails/actiontext")

import Initializers from "../initializers";

const imagesContext = require.context('../images', true, /\.(gif|jpg|png|svg)$/i);

const imagePath = name => imagesContext(name, true)

Initializers.run()
