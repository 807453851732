export default class Menu {
  static init() {
    new Menu().start();
  }

  constructor() {
    this.mobileMenuButton = document.querySelector('button.mobile-menu-button');
    this.mobileMenu = document.querySelector('.mobile-menu');
  }

  start() {
    this.mobileMenuButton.addEventListener('click', () => {
      this.mobileMenu.classList.toggle('hidden')
    });
  }
}
