export default class PhoneInputMask {
  static init() {
    new PhoneInputMask().start();
  }

  start() {
    let mobilePhones = document.getElementsByClassName('masked-mobile-phone');

    Array.from(mobilePhones).forEach((mobilePhone) => {
      let inputMask = new Inputmask('(99)99999-9999');
      inputMask.mask(mobilePhone);
    });
  }
}
