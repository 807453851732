import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import ptbrLocale from '@fullcalendar/core/locales/pt-br'

export default class FullCalendarJS {
  static init() {
    new FullCalendarJS().start()
  }

  constructor() {
    this.calendarElement = document.getElementById('calendar')

    if (this.calendarElement) {
      this.calendarEventType = this.calendarElement.dataset.calendarType
      this.caledarTypeId = this.calendarElement.dataset.caledarTypeId
    }
  }

  async start() {
    console.log("starting")
    if (!this.calendarElement) {
      return;
    }
    console.log("there is a calendar")

    const headerToolbars = this.calendarEventType === 'user' ?
      {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,listWeek'
      } : {
        left: '',
        center: '',
        right: ''
      }

    let calendar = new Calendar(this.calendarElement, {
      locale: ptbrLocale,
      height: 550,
      buttonText: {
        today: 'Hoje',
        month: 'Mês',
        week: 'Sem.',
        day: 'Dia',
        list: 'Lista'
      },
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin ],
      initialView: 'dayGridMonth',
      headerToolbar: headerToolbars,
      events: {
        url: `/events.json?type=${this.calendarEventType}&type_id=${this.caledarTypeId}`,
        type: 'GET',
        dataType: 'jsonp',
        color: '#580D3E',
        textColor: '#333'
      },
      // eventClassNames: function(arg) {
      //   console.log('Calendar')
      //   return [ 'modal-open' ]
      // },
      eventClick: function(info) {
        fetch(`/events/${info.event.id}`).then(response => {
          return response.text()
        }).then(html => {
          // Convert the HTML string into a document object
          const parser = new DOMParser()
          const htmlObject = parser.parseFromString(html, 'text/html')

          // Dynamic Values
          document.getElementById('modal-content').innerHTML = htmlObject.body.innerHTML
          document.getElementById('modal-subject').innerHTML = info.event.title

          // Modal open
          document.getElementById('modal-self-open').click()
        }).catch(err => {
          // There was an error
          console.warn('Something went wrong.', err)
        });
      }
    });

    calendar.render()

    // Bug fix responsible screen after fullcalendar initialize
    const dayGridMonth = document.getElementsByClassName('fc-dayGridMonth-button')[0]
    if (dayGridMonth) setTimeout(() => dayGridMonth.click(), 2000)
  }
}
