import { TimeColsSlatsCoords } from "@fullcalendar/timegrid";

export default class Modal {
  static applyForTask({onConfirm = function() {}}) {
    const modalConfirm = document.getElementById('modal-confirm');

    modalConfirm.addEventListener('click', (_) => { onConfirm() })
  }

  static init() {
    new Modal().start();
  }

  constructor() {
    this.modalClose = document.querySelectorAll('.modal-close');
    this.modalConfirm = document.getElementById('modal-confirm');
    this.modalOverlay = document.querySelector('.modal-overlay');
    this.modalSubject = document.getElementById('modal-subject');
    this.modalContent = document.getElementById('modal-content');

    this.showingModal = false;
  }

  toggleModal() {
    const body = document.querySelector('body')
    const modal = document.querySelector('.modal')

    modal.classList.toggle('opacity-0')
    modal.classList.toggle('pointer-events-none')
    body.classList.toggle('modal-active')
  }

  start() {
    const _thisModalClose = this.modalClose;
    let thisShowingModal = this.showingModal;

    // TODO: REFACTOR IT
    document.getElementById('modal-self-open').addEventListener('click', (event) => {
        this.toggleModal();
    });

    // Add modal listeeners
    document.querySelectorAll('.modal-open').forEach((item, _) => {
      item.addEventListener('click', (event) => {
        event.preventDefault()

        const { modalSubject, modalContent } = event.target.dataset;

        this.modalSubject.innerText = modalSubject;
        this.modalContent.innerHTML = modalContent;

        if (!thisShowingModal) {
          Modal.applyForTask({
            onConfirm: () => {
              // TODO: Make ajax request to the task application/enroll
              console.log('ACTION - Enroll to task confirmation');

              thisShowingModal = false;

              _thisModalClose[_thisModalClose.length - 1].click();
            }
          });

          thisShowingModal = true;
        }

        this.toggleModal();
      })
    })

    // Add overlay listener
    this.modalOverlay.addEventListener('click', this.toggleModal)

    // Add modal close listener
    this.modalClose.forEach((item, _) => { item.addEventListener('click', this.toggleModal) })

    // Document event to the modal
    document.onkeydown = (evt) => {
      evt = evt || window.event

      var isEscape = false

      if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc")
      } else {
        isEscape = (evt.keyCode === 27)
      }

      if (isEscape && document.querySelector('body').classList.contains('modal-active')) {
        this.toggleModal()
      }
    };
  }
}
