export default class Preferences {
  static init() {
    new Preferences().start();
  }

  constructor() {
    this.preferencesCheckbox = document.getElementById('toggle-preferences');
  }

  start() {
    if (this.preferencesCheckbox) {
      this.preferencesCheckbox.addEventListener('click', (event) => {
        event.target?.parentElement?.parentElement?.submit()
      });
    }
  }
}
